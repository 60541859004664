.BlogList {
    display: flex;
    justify-content: center;
    padding: 16px;
}
.BlogList .links {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    justify-content: left;
}
.BlogList .links .entry {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.25rem;
}
.BlogList .links .entry .date {
    flex-basis: 20%;
    flex-grow: 1;
    padding-bottom: 16px;
}
.BlogList .links .entry a {
    flex-basis: 80%;
    flex-grow: 1;
    padding-bottom: 32px;
    text-align: center;
}
