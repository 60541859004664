.BlogPost {
    display: flex;
    justify-content: center;
}
.BlogPost h1 {
    font-size: 2.25rem;
    line-height: 3rem;
    text-align: center;
    margin: 0 0 16px;
}
.BlogPost .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
.BlogPost .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.BlogPost .columns .social-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: right;
    margin: 6px 16px;
    max-width: 100px;
}
.BlogPost .columns .social-buttons > * {
    margin: 0 0 8px;
}
@media only screen and (max-width: 700px) {
    .BlogPost .columns .social-buttons {
        flex-direction: row;
        text-align: right;
        max-width: 100vw;
        margin: 0 16px 8px;
    }
    .BlogPost .columns .social-buttons > * {
        margin: 0 4px;
    }
}
.BlogPost .columns .blog {
    max-width: 600px;
}
.BlogPost .columns .blog .ShirtItem {
    margin-bottom: 8px;
    max-width: 480px;
}
.BlogPost .columns .blog .content {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    margin-top: 16px;
    padding: 8px 8px 32px;
}
.BlogPost .columns .blog .next-post {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    padding: 8px 8px 32px;
}
.BlogPost .columns .blog .hilarious-tweet {
    max-width: 100vw;
}
.BlogPost .columns .recent-blogs {
    display: flex;
    flex-direction: column;
    max-width: 320px;
}
.BlogPost .columns .recent-blogs h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 16px;
    border-bottom: black dashed 1px;
}
.BlogPost .columns .recent-blogs a {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-bottom: 16px;
    font-size: 1.25rem;
    border-bottom: black dashed 1px;
}
/* Fallback embedded Tweet styles */
blockquote.twitter-tweet {
    display: inline-block;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 10px 5px;
    padding: 0 16px 16px 16px;
    max-width: 468px;
}
blockquote.twitter-tweet p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
}
blockquote.twitter-tweet a {
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    outline: 0 none;
}
blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
    text-decoration: underline;
}