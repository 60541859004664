.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 16px 0;
}
@media only screen and (max-width: 800px) {
    .Header {
        justify-content: center;
    }
}
.Header .logo {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: max-content;
}
.Header .logo img {
    width: 96px;
    height: 96px;
}
.Header .logo .title {
    padding-top: 8px;
    margin-left: 16px;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
}
.Header .menu {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: max-content;
    align-items: center;
}
.Header .menu a {
    padding: 12px 16px;
    font-size: 1.25rem;
}
