.BlackLives {
    background-color:black;
    color: white;
    text-align: center;
    font-size: 3em;
    padding-bottom: .5em;
    padding-top: .5em;
}

.BlackLives a{
    text-decoration: none;
    color: white;
}

.BlackLives a:visited {
    text-decoration: none;
    color: white;
}