.RedditPostButton {
    background-color: #fc471e;
    border-radius: 3px;
    text-align: left;
    padding: 1px 7px 1px 4px;
    color: #ffffff;
    text-decoration: none;
    font-size: .75rem;
    font-weight: bold;
}
.RedditPostButton img {
    width: 18px;
    vertical-align: middle;
}
.RedditPostButton span {
    margin: 0 0 0 2px;
    vertical-align: middle;
}
