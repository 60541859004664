.AboutUs {
    display: flex;
    justify-content: center;
    padding: 32px;
}
.AboutUs .text {
    max-width: 600px;
    font-size: 1.75rem;
    text-align: left;
    line-height: 2.5rem;
}
