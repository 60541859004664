.ShirtItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0 0 5px #cccccc;
    margin: 8px;
    text-align: center;
    padding: 8px 8px 12px;
}
.ShirtItem .description {
    font-size: 1.25rem;
}
.ShirtItem img {
    width: 100%;
}
.ShirtItem p {
    margin-top: 0;
}
.ShirtItem a {
    display: block;
    margin-bottom: 8px;
}