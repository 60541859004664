.ErrorMessage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.ErrorMessage .icon {
    font-size: 5rem;
}
.ErrorMessage .error {
    background-color: #FFCDD2;
    border-radius: 6px;
    padding: 8px;
    max-width: 540px;
    font-size: 1.5rem;
    box-shadow: 0 0 5px #cccccc;
}
