.BuyButton {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 2px #888888;
    padding: 8px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #0052A5;
    border: none;
}